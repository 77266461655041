import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import ButtonBase from '@material-ui/core/ButtonBase';
import PropTypes from 'prop-types';
import Typography from '@material-ui/core/Typography';


const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        minWidth: 300,
        width: '100%',
    },
    container: {
        textAlign: 'center',
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    menu: {
        width: 200,
    },
    image: {
        position: 'relative',
        height: 200,
        [theme.breakpoints.down('xs')]: {
            width: '100% !important', // Overrides inline-style
            height: 100,
        },
        '&:hover, &$focusVisible': {
            zIndex: 1,
            '& $imageBackdrop': {
                opacity: 0.15,
            },
            '& $imageMarked': {
                opacity: 0,
            },
            '& $imageTitle': {
                border: '4px solid #f44336',
            },
            '& $imageButton': {
                color: '#f44336',
            },
        },
    },
    focusVisible: {},
    imageButton: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        color: theme.palette.common.white,
    },
    imageSrc: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundSize: 'cover',
        backgroundPosition: 'center 40%',
    },
    imageBackdrop: {
        position: 'absolute',
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#f44336",
        // opacity: 0.4,
        transition: theme.transitions.create('opacity'),
    },
    imageTitle: {
        position: 'relative',
        padding: `${theme.spacing(2)}px ${theme.spacing(4)}px ${theme.spacing(1) + 6}px`,
    },
    imageMarked: {
        height: 3,
        width: 18,
        backgroundColor: theme.palette.common.white,
        position: 'absolute',
        bottom: -2,
        left: 'calc(50% - 9px)',
        transition: theme.transitions.create('opacity'),
    },
    button: {
        display: 'block',
        marginTop: theme.spacing(2),
    },
    formControl: {
        margin: theme.spacing(1),
        minWidth: 120,
    },
}));
function AnswerOption(props) {

    const classes = useStyles();

    const [values, setValues] = React.useState({
        name: ''
    });
    // eslint-disable-next-line
    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
    };


            return (

                <ButtonBase
                    focusRipple
                    key={props.answerContent}
                    className={classes.image}
                    focusVisibleClassName={classes.focusVisible}
                    style={{
                        width: props.width,
                    }}
                    answer={props.answer}

                    value={props.answerType}
                    onClick={(event) => { return props.onAnswerSelected(event, props.answer, props.questionId) }}


                >
                    <span
                        className={classes.imageSrc}
                        style={{
                            backgroundImage: `url(${props.image})`,
                        }}
                    />
                    <span className={classes.imageBackdrop} />
                    <span className={classes.imageButton}>
                        <Typography
                            component="span"
                            variant="h5"
                            color="inherit"
                            className={classes.imageTitle}
                            
                        >
                            {props.answerContent}
                            <span className={classes.imageMarked} />
                        </Typography>
                    </span>
                </ButtonBase>



            );


    

}

AnswerOption.propTypes = {
    answerType: PropTypes.string.isRequired,
    answerContent: PropTypes.string,
    answer: PropTypes.string,
    width: PropTypes.string,
    image: PropTypes.string,
    onAnswerSelected: PropTypes.func.isRequired
};

export default AnswerOption;
