import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import NativeSelect from '@material-ui/core/NativeSelect';
import InputBase from '@material-ui/core/InputBase';

import PropTypes from 'prop-types';

const BootstrapInput = withStyles(theme => ({
    root: {
        'label + &': {
            marginTop: theme.spacing(3),
        },
       
    },
    input: {
        borderRadius: 4,
        position: 'relative',
        backgroundColor: theme.palette.background.paper,
        border: '1px solid #ced4da',
        fontSize: 16,
        padding: '10px 26px 10px 12px',
        transition: theme.transitions.create(['border-color', 'box-shadow']),
        // Use the system font instead of the default Roboto font.
        '&:focus': {
            borderRadius: 4,
            borderColor: '#80bdff',
            boxShadow: '0 0 0 0.2rem rgba(0,123,255,.25)',
        },
    },
}))(InputBase);

const useStyles = makeStyles(theme => ({
    root: {
        display: 'flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    margin: {
        margin: theme.spacing(1),
    },
}));

function AnswerSelect(props) {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        name: ''
    });
    const handleChange = name => event => {
        
        setValues({ ...values, [name]: event.target.value });
    };

    function renderSelects(regioni) {
        return regioni.map((regione) => {
            return regione.regioni.map((data, index) => {

                return (<option key={index} value={data.value}>{data.regione}</option>)
            }
            )
        })
    }


    return (
        <form className={classes.root} autoComplete="off">


            <FormControl className={classes.margin}>
                <InputLabel htmlFor="age-customized-native-simple">Regione</InputLabel>
                <NativeSelect
                    value={props.answerContent}
                    onChange={(event) => {handleChange();props.onAnswerSelected(event, event.target.value,props.questionId)}}
                    input={<BootstrapInput name="age" id="age-customized-native-simple" />}
                >
                    <option  value="" >Selezione Regione</option>
                    {renderSelects(props.answerOptions)}
                </NativeSelect>
            </FormControl>
        </form>
    );
}

AnswerSelect.propTypes = {
    answerContent: PropTypes.string,
    answer: PropTypes.string,
    answerOptions: PropTypes.array.isRequired,
    questionId: PropTypes.number
};

export default AnswerSelect;