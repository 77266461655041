import motore from '../images/units/motore.png';


  import vito300split from '../images/units/vitoclima-300-s-split.png'
  import vito300hidden from '../images/units/vitoclima-242-s-hidden.png'
  import vito300builtin from '../images/units/vitoclima-300-s-builtin.png'
  import vito300fancoil from '../images/units/vitoclima-300-s-fancoil.png'


//   import vito242builtin from '../images/units/vitoclima-300-s-builtin.png'
  import vito242hidden from '../images/units/vitoclima-242-s-hidden.png'

  import vito200split from '../images/units/vitoclima-200-s-split.png'

//manca 232 white
  import vito232split from '../images/units/vitoclima-232-s.png'

  import vito232splitBlack from '../images/units/vitoclima-232-s-black.png'

  
import PdfVitoclima300s from '../pdf/vitoclima_300_s.pdf';
import PdfVitoclima242s from '../pdf/vitoclima_242_s.pdf';
import PdfVitoclima200s from '../pdf/vitoclima_200_s.pdf';
import PdfVitoclima232s from '../pdf/vitoclima_232_s.pdf';

//Highlight Object for match icon and descr
var hl = {
    r32: {
        text: 'R32',
        iconClass: 'fa-leaf'
    },
    combination: {
        text: 'Più di 150 combinazioni',
        iconClass: 'fa-wave-square'
    },
    fiscalDetr: {
        text: 'Detrazioni fiscali e Conto Termico',
        iconClass: 'fa-euro-sign'
    },
    wifi: {
        text: 'Wi-Fi',
        iconClass: 'fa-wifi'
    },
    sanification: {
        text: 'Sanificazione dell\'aria',
        iconClass: 'fa-shield-virus'
    },
    silent: {
        text: 'Silenziosità',
        iconClass:'fa-volume-mute'
    },
    design: {
        text: 'Design',
        iconClass:'fa-drafting-compass'
    }
}


var res = {


    vitoclima300s: {
        title:'Vitoclima 300-S',
        desc: 'Vitoclima 300-S è il condizionatore multisplit free-match di ultima generazione che abbina l\'efficienza e i bassi consumi energetici all\'utilizzo del gas refrigerante R32 a basso impatto ambientale. E\' possibile abbinare ad un\'unica unità esterna fino a 5 unità interne, anche di tipo diverso, e ciò consente di scegliere quella più adatta in funzione dell\'ambiente da climatizzare (fino a 150 combinazioni, da 18.000 a 42.000 Btu/h).',
        powerClass: '',
        energyClass: ['cA++', 'hA+'],
        highlights:[hl.r32, hl.fiscalDetr, hl.combination, hl.wifi, hl.sanification],
        ui: {
            fancoil: vito300fancoil,
            split: vito300split,
            hidden: vito300hidden,
            builtin: vito300builtin
        },
        ue: motore,
        pdf: PdfVitoclima300s
    },
    vitoclima242s: {
        title:'Vitoclima 242-S',
        desc: 'Vitoclima 242-S è la soluzione ideale per climatizzare con un tocco di design la tua abitazione attraverso unità monosplit canalizzate. Disponibile in varie versioni (a cassetta, canalizzato, a soffitto/pavimento), è adatto a ogni tipo di installazione grazie alla sua grande versatilità. L’intera gamma Vitoclima 242-S utilizza il refrigerante R32, che presenta numerosi vantaggi per la salvaguardia dell’ambiente e per il risparmio',
        powerClass: '',
        energyClass: ['cA++', 'hA+'],
        highlights:[hl.r32,hl.fiscalDetr, hl.design, hl.wifi, hl.sanification],
        ui: {
            builtin: vito242hidden
        },
        ue: motore,
        pdf: PdfVitoclima242s

    },
    vitoclima200s: {
        title:'Vitoclima 200-S Comfort',
        desc: 'Vitoclima 200-S Comfort è il nuovo condizionatore monosplit, sinonimo di eccellente rapporto qualità prezzo. Ideale per installazioni residenziali, coniuga efficienza e silenziosità con un design esclusivo. Come tutta i prodotti della gamma Viessmann, anche questo climatizzatore utilizza il gas refrigerante R32, che presenta grandi vantaggi per la salvaguardia dell’ambiente in termini di riduzione di CO2 e dal punto di vista energetico. ed è dotato del modulo di sanificazione integrato a plasma freddo per sanificare l\'aria indoor.',
        powerClass: '',
        energyClass: ['cA++', 'hA+'],
        highlights:[hl.r32, hl.fiscalDetr, hl.design, hl.wifi, hl.sanification],
        ui: {
            split: vito200split,

        },
        ue: motore,
        pdf: PdfVitoclima200s

    },
    vitoclima232s: {
        title:'Vitoclima 232-S',
        desc: 'Vitoclima 232-S è un climatizzatore monosplit di categoria superiore, che raggiunge la classe energetica A+++ garantendo bassi consumi energetici, massimo comfort acustico e ottima qualità dell‘aria nella tua abitazione. Grazie alla tecnologia a plasma freddo o NTP (non thermal plasma), Vitoclima 232-S è in grado di ridurre fino al 91% la presenza di agenti patogeni in ambiente in sole 2 ore.',
        powerClass: '',
        energyClass: ['cA+++', 'hA+++'],
        highlights:[hl.r32, hl.fiscalDetr, hl.silent, hl.wifi, hl.sanification],
        ui: {
            split: vito232split,
            splitBlack: vito232splitBlack,

        },
        ue: motore,
        pdf: PdfVitoclima232s


    },
};

export default res;
