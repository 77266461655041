import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import PropTypes from 'prop-types';
import InputAdornment from '@material-ui/core/InputAdornment';



const useStyles = makeStyles(theme => ({

    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    textDanger:{
        color: "red",
    },
    dense: {
        marginTop: theme.spacing(2),
    },
}));

function MyTextField(props) {
    const classes = useStyles();
    const [values, setValues] = React.useState({
        name: [],
        errorMessage: []
    });

    const handleChange = name => event => {
        setValues({ ...values, [name]: event.target.value });
        props.onChange(event)
    };

    return (
    

                <TextField
                    label={"Stanza " + props.RoomsNumber}
                    value={values.name}
                    onChange={handleChange('name')}
                    type="number"
                    error={props.error}
                    className={classes.textField}
                    required
                    InputLabelProps={{
                        shrink: true,
                    }}
                    InputProps={{
                        endAdornment: <InputAdornment position="end">Mq</InputAdornment>,
                    }}
                    margin="normal"
                    variant="outlined"
                    helperText={props.errorMessage}
                />
    
    );
}
MyTextField.propTypes = {

    RoomsNumber: PropTypes.string.isRequired,


    

};
export default MyTextField;