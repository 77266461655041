import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import PropTypes from 'prop-types';
import TextField from '../components/TextField';
import Button from '@material-ui/core/Button';



const useStyles = makeStyles(theme => ({
    outer: {

        textAlign: 'center',
        padding: '25px',
    },
    main: {
        display: 'flex',
        justifyContent: 'center'
    },
    container: {
        display: 'inline-flex',
        flexWrap: 'wrap',
        justifyContent: 'center'
    },
    textField: {
        marginLeft: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    dense: {
        marginTop: theme.spacing(2),
    },
    button:{
        backgroundColor: '#ff3e17',
        fontSize: '18px',
        textTransform: 'unset',
        boxShadow: 'unset',
        border: '1px solid',

        '&:hover': {
            backgroundColor: 'unset',
            color: '#ff3e17',
            border: '1px solid'
         },
    }

}));

function MyTextFieldContainer(props) {
    const classes = useStyles();
    const [state, setState] = React.useState({
        results: [],
    });
    const [warning, setWarning] = React.useState([]);
    const handleChange = index => event => {
        let results = state.results
        results[index] = event.target.value
        // console.log(results)
        // console.log(state)
        setState({ ...state, results })
    };

    const validate = index => event => {
        let localWarning = [];
        for (let index = 0; index < fields.length; index++) {
            if (typeof (state.results[index]) === "undefined" || state.results[index] === "") {

                // alert("Inserisci un valore valido per la stanza "+ (index+1));
                // console.log(fields)
                localWarning.push(index);
                setWarning(localWarning)
            }

            if(parseInt(state.results[index]) > 35){
                localWarning.push(index);
                setWarning(localWarning)
                

            }

        }
        if (localWarning.length === 0) {

            props.onAnswerSelected(event, state.results, props.questionId)

        }
    };
    const fields = []
    // console.log(props.RoomsCount)
    // console.log(props.RoomsCount[props.questionId - 1])
    for (var i = 0; i < parseInt(props.RoomsCount); i++) {
        // console.log(warning[])
        fields.push(<TextField key={String(i + 1)} RoomsNumber={String(i + 1)} error={warning.includes(i) ? true : false} errorMessage={warning.includes(i) ? "Inserire un valore valido" : ""} onChange={handleChange(i)} />)
    }
    return (
        <div className={classes.outer}>
            <div className={classes.main}>
                <div className={classes.container}>
                    {fields}
                </div>

            </div>
            <Button className={classes.button} variant="contained" color="primary" onClick={validate()} >Avanti</Button>

        </div >
    )


}








MyTextFieldContainer.propTypes = {

    RoomsCount: PropTypes.number.isRequired,
    questionId: PropTypes.number.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
    answer: PropTypes.object,

};

export default MyTextFieldContainer;