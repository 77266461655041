import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { animations } from 'react-animation'
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import Results from '../api/quizResults.js';
import heat from '../images/Heat.svg';
import cold from '../images/Cold.svg';
import Icon from '@material-ui/core/Icon';
import Button from '@material-ui/core/Button';
import GetAppIcon from '@material-ui/icons/GetApp';
import PlayArrow from '@material-ui/icons/PlayArrow';
import Box from '@material-ui/core/Box';
import clsx from 'clsx';
import { loadCSS } from 'fg-loadcss';
function Result(props) {











    React.useEffect(() => {
        loadCSS(
            'https://cdnjs.cloudflare.com/ajax/libs/font-awesome/5.15.3/css/all.min.css',
            document.querySelector('#font-awesome-css'),
        );
    }, []);




    const style = {
        animation: animations.fadeInUp
    }
    const useStyles = makeStyles(theme => ({
        root: {
            // paddingBottom: '100px',
            paddingTop: '20px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
            // background: 'url("https://cdn0.scrvt.com/2828ebc457efab95be01dd36047e3b52/4404c3fff65bfd7b/7e8af90241b3/v/2b9ee4c56a2c/cta_bg2.png") center center / cover no-repeat scroll padding-box border-box transparent',
        },
        connectorActive: {
            '& $connectorLine': {
                borderColor: '#ff3e17',
            },
        },
        container: {
            // paddingBottom: '40px',
            paddingTop: '40px',
            width: '90%',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '14px 17px 50px 0px rgba(0,0,0,0.55)'

        },
        containerFull: {

            borderRadius: '10px',
            paddingTop: '20px',
            width: '100%',
            background: 'white',
           
    
        },
        productImageUI: {
            display: 'flex',
            flexDirection: 'row',
            flexWrap: 'wrap',
            justifyContent: 'space-around',
            padding: '20px',
            alignItems: 'center',
            marginTop: '10px',
            [theme.breakpoints.down('sm')]: {
                flex: '100%',
            },
            [theme.breakpoints.up('md')]: {
                flex: '25%',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '33%',
            },
            position: 'relative'
        },

        productImageUE: {
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
            padding: '20px',
            alignItems: 'center',
            marginTop: '10px',
            [theme.breakpoints.down('sm')]: {
                flex: '100%',
            },
            [theme.breakpoints.up('md')]: {
                flex: '25%',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '33%',
            },
            position: 'relative'
        },
        descriptionContainer: {
            [theme.breakpoints.down('sm')]: {
                flex: '100%',
            },
            [theme.breakpoints.up('md')]: {
                flex: '50%',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '50%',
            },
            padding: '20px',
            paddingRight: '40px',
            textAlign: 'left',
            position: 'relative',
            justifyContent: 'flex-start',
            flexWrap: 'wrap'

        },
        imageContainer: {
            [theme.breakpoints.down('sm')]: {
                flex: '100%',
            },
            [theme.breakpoints.up('md')]: {
                flex: '50%',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '50%',
            },
            flexDirection: 'column',
            alignItems: 'center'

        },
        downDescription: {
            padding: '30px',
            [theme.breakpoints.down('sm')]: {
                flex: '100%',
            },
            [theme.breakpoints.up('md')]: {
                flex: '100%',
            },
            [theme.breakpoints.up('lg')]: {
                flex: '100%',
            },

            background: '#ff3e17',
            color: 'white',
            borderRadius: '0 0 10px 10px'
            //  boxShadow: '14px 17px 50px 0px rgba(0,0,0,0.55)'

        },
        imageUE: {

            [theme.breakpoints.down('xs')]: {
                width: '200px',
            },
            [theme.breakpoints.up('sm')]: {
                width: '300px',
            },
            [theme.breakpoints.up('md')]: {
                width: '300px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '350px',
            },
            marginTop: '10px',
        },
        imageUI: {
            [theme.breakpoints.down('xs')]: {
                width: '150px',
            },
            [theme.breakpoints.up('sm')]: {
                width: '220px',
            },
            [theme.breakpoints.up('md')]: {
                width: '220px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '260px',
            },
            marginBottom: '10px',
            padding: '10px'
        },
        imageUISingle: {
            [theme.breakpoints.down('xs')]: {
                width: '270px',
            },
            [theme.breakpoints.up('sm')]: {
                width: '270px',
            },
            [theme.breakpoints.up('md')]: {
                width: '270px',
            },
            [theme.breakpoints.up('lg')]: {
                width: '320px',
            },
            marginBottom: '5px',
            padding: '5px'
        },
        backButton: {
            marginRight: theme.spacing(1),
        },
        completed: {
            display: 'inline-block',
        },
        instructions: {
            marginTop: theme.spacing(1),
            marginBottom: theme.spacing(1),
        },
        textContainerLeft: {
            // fontFamily: 'Inter',

        },
        textContainerRight: {
            // fontFamily: 'Inter',
            color: 'white',
            paddingRight: '25px',
            paddingLeft: '25px'
        },
        textTitle: {
            color: '#ff3e17'
        },
        ctaDescription: {
            flex: '70%',
            flexGrow: '1'
        },
        ctaButton: {
            flex: '30%',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'space-around',
        }
        ,
        icons: {
            margin: 'auto',
            alignItems: 'flex-start',
            justifyContent: 'center',
            color: '#ff3e17',
            textAlign: 'center',

        },
        icon: {
            margin: 'auto',
            width: '100%'
        },
        leggend: {
            marginTop: '20px',
            fontSize: '10px',
            padding: '20px'
        },
        iconHover: {
            flex: '1 1 100',
            fontSize: '40px'
        },
        stickyCold: {
            position: 'absolute',
            top: '210px',
            right: '0px',
            width: '46px'
        },
        stickyHeat: {
            position: 'absolute',
            top: '240px',
            right: '0px',
            width: '46px'
        },
        submitMail: {
            // width: '100%'
        },
        textTitleRight: {
            // color:'white',
            // background:'#c1c1c1',
            padding: '10px',
            paddingLeft: '25px'
        },
        textField: {
            background: 'white',
            // borderColor: 'white',

        },
        button: {
            backgroundColor: '#fff',
            fontSize: '18px',
            textTransform: 'unset',
            boxShadow: 'unset',
            border: '1px solid',
            color: '#ff3e17',
            marginRight: theme.spacing(1),

            '&:hover': {
                backgroundColor: 'unset',
                color: '#fff',
                border: '1px solid'
            },
        },
        downloadButton: {
            backgroundColor: '#ff3e17',
            fontSize: '12px',
            textTransform: 'unset',
            boxShadow: 'unset',
            color: '#fff',
            border: '1px solid',
           
            // marginRight: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                width: '50%',
                fontSize: '12px',
            },
            [theme.breakpoints.up('sm')]: {
                width: '50%',
            },
            [theme.breakpoints.up('md')]: {
                width: '20%',
                float: 'right',
            },
            [theme.breakpoints.up('lg')]: {
                width: '20%',
                float: 'right',
            },
            '&:after':{
                display: 'block',
                clear: 'both',
                content: "" 
        
            },
            '&:hover': {
                backgroundColor: 'unset',
                color: '#ff3e17',
                border: '1px solid',
                boxShadow: 'unset',

            },
        },
        nextButton: {
            backgroundColor: '#ff3e17',
            fontSize: '18px',
            textTransform: 'unset',
            boxShadow: 'unset',
            color: '#fff',
            border: '1px solid',
            // marginRight: theme.spacing(1),
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                width: '50%',
            },
            [theme.breakpoints.up('lg')]: {
                width: '50%',
            },

            '&:hover': {
                backgroundColor: 'unset',
                color: '#ff3e17',
                border: '1px solid',
                boxShadow: 'unset',

            },
            '&:after':{

            }
        }


    }));


    const result = props.result;

    var resultTitle = ""
    var resultSubTitle = ""
    var resultDescr = ""
    var resultUE = ""
    var resultUI = ""


    resultTitle = Results[result.code].title;
    resultSubTitle = 'Potenza richiesta : ' + ((result.power * 0.001).toFixed(2) * 3412).toFixed(0) + ' Btu/h'
    resultDescr = Results[result.code].desc;
    resultUE = Results[result.code].ue;
    resultUI = result.color !== "black" ? Results[result.code].ui[result.type] : Results[result.code].ui[result.type + 'Black'];

    function ScaledIcod(props) {
        return (
          <PlayArrow {...props} style={{
            transform: 'scale(1.5)' // Tune it
          }}>
            
          </PlayArrow>
        );
      }


    var highlights = []

    const classes = useStyles();


    Results[result.code].highlights.forEach(element => {
        highlights.push(<Box key={element.iconClass}
            display='flex'
            flexDirection='column'
            flex={Results[result.code].highlights.lenght === 4 ? '25%' : '20%'}
        >
            <Icon className={clsx(classes.icon, 'fas ' + element.iconClass)} />
            <p>{element.text}</p>
        </Box>
        )

    });



    return (


        <div style={style} className={classes.root}>

            <div className={classes.containerFull} >
            
                    <Stepper alternativeLabel nonLinear activeStep={1}  >
                        <Step key="0">

                            <StepButton
                                onClick={() => window.location.reload()}
                            >
                                {"1/3"}
                            </StepButton>
                        </Step>
                        <Step key="1" >

                            <StepButton >
                                {"2/3"}
                            </StepButton>
                        </Step>
                        <Step key="2">

                            <StepButton >
                                {"3 /3"}
                            </StepButton>
                        </Step>
                    </Stepper>
             
                <Box display="flex" justifyContent="center" flexWrap="wrap">
                <h1 className={classes.textTitle}>{resultTitle}</h1>
                    <Box display="flex" justifyContent="flex-start" flexWrap="wrap">
                    <Box display="flex" className={classes.imageContainer} >
                        <img className={classes.stickyCold} src={cold} alt="" />
                                <img className={classes.stickyHeat} src={heat} alt="" />
                            <Box className={classes.productImageUI} >
                       
                                {result.UI.map((code, index) => (

                                    <img key={index} className={result.UI.length === 1 ? classes.imageUISingle : classes.imageUI} src={resultUI} alt={"VitoCLima " + code} />
                                ))}
                            </Box>
                            <Icon
                                className={clsx(classes.iconHover, 'fa fa-plus-circle')}
                                color="error"
                            />
                            <Box className={classes.productImageUE} >

                                <img className={classes.imageUE} src={resultUE} alt="VitoCLima" />
                            </Box>

                        </Box>
                      
                    <Box display="flex" className={classes.descriptionContainer}>
                            <div className={classes.description}>
                            
                             
                                <h2 className={classes.textSubTitle}>{resultSubTitle} *</h2>
                                <p className={classes.textContainerLeft}>{resultDescr}</p>
                                <Box display='flex' className={classes.icons} flexWrap="wrap" >


                                    {highlights}



                                </Box>
                                <Button
                                    className={classes.nextButton}
                                    variant="contained"
                                    color="default"
                                    onClick={props.showForm}
                                    startIcon={<ScaledIcod />}
                                >
                                    Richiedi un preventivo
                                </Button>
                                <Button
                                    className={classes.downloadButton}
                                    variant="contained"
                                    color="default"
                                    onClick={() => { window.open(Results[result.code].pdf) }}
                                    startIcon={<GetAppIcon />}
                                >
                                    Scarica Pdf
                                </Button>
                                <br />
                                <p className={classes.leggend}>* In base alle tue scelte e alla situazione attuale, ti consigliamo la seguente soluzione per la climatizzazione di casa. Si prega di notare che questa è un'indicazione di massima e non dovrebbe essere usata come un'indicazione specifica e definitiva. Richiedi una consulenza gratuita da parte di un installatore Partner Viessmann specialista di prodotto, così da confermare le tue esigenze e le tue necessità.</p>

                            </div>

                        </Box>

                     
                    </Box>

                    <Box display="flex" className={classes.downDescription} flexDirection="row" justifyContent="center" flexWrap="wrap">
                        <div className={classes.ctaDescription}>
                            <h1 className={classes.textTitleRight}>Ti piace la soluzione proposta?<br></br>Ricevi subito il tuo preventivo gratuito!</h1>
                            <p className={classes.textContainerRight}>Condividici in meno di un minuto alcuni tuoi dati per essere contattato dall'installatore qualificato Partner Viessmann vicino a te. Richiedi un preventivo</p>
                            

                        </div>
                        <div className={classes.ctaButton}>

                            <Button className={classes.button} onClick={props.showForm} variant="contained" color="primary" >Contattaci</Button>
                        </div>
                    </Box>


                </Box>






            </div>
        </div>
    );
}

Result.propTypes = {
    answer: PropTypes.object.isRequired,
};

export default Result;
