import React, { Component } from 'react';
// import logo from './images/viessmann-logo.svg';
import Quiz from './containers/Quiz';
import quizQuestions from './api/quizQuestions';
import Result from './components/Result';
import Form from './containers/Form';
import { MuiThemeProvider, createMuiTheme } from '@material-ui/core/styles';

import './App.css';

const muiTheme = createMuiTheme({
  overrides: {
    MuiStepIcon: {
      root: {
        'height': '90%',
        '&$active': {
          color: '#ff3e17',
        },

      },
     

    }
  },
  typography: {
    "fontFamily": '"Roboto", "Helvetica", "Arial", sans-serif',
    "fontSize": 14,
    "fontWeightLight": 300,
    "fontWeightRegular": 400,
    "fontWeightMedium": 500
  }
});

class App extends Component {
  constructor(props) {
    super(props);

    this.state = {
      lastQuestion: false,
      id: 0,
      counter: 0,
      questionId: 0,
      question: '',
      answerOptions: [],
      answer: {},
      rooms: 1,
      showForm: false,
      answersCount: {},
      result: {
        code: '',
        UI: '',
        UE: [],
        uiType: '',
        color: '',
        power: 0
      }
    };
    this.showForm = this.showForm.bind(this);
    this.handleAnswerSelected = this.handleAnswerSelected.bind(this);
    this.handleReset = this.handleReset.bind(this);

  }

  componentDidMount() {
    // console.log("Component DID MOUNT")
    const answerOptions = quizQuestions.map(question =>
      (question.answers)
    );
    this.setState({
      id: quizQuestions[0].id,
      question: quizQuestions[0].question,
      answerOptions: answerOptions[0]
    });

  }
  handleReset() {
    const answerOptions = quizQuestions.map(question =>
      (question.answers)
    );
    this.setState({
      id: quizQuestions[0].id,
      question: quizQuestions[0].question,
      answerOptions: answerOptions[0],
      counter: 0,
      questionId: 0,
      answer: {},
      rooms: 1,
      showForm: false,
      answersCount: {},
      result: {
        code: '',
        UI: '',
        UE: [],
        uiType: '',
        color: '',
        power: 0
      }
    });
  }

  handleAnswerSelected(event, answer, id) {

    this.setUserAnswer(answer, id);
    // console.log("SET USER RESP:", answer)
    // this.setNextQuestion()
    setTimeout(() => this.setNextQuestion(), 0);

  }

  setUserAnswer = (answer, id) => {
    // console.log("SET USER answer:", answer)
    // console.log("SET USER id:", id)

    // var newAnswer = { ...this.state.answer, [id]: answer };

    // console.log("SET USER new answer:", newAnswer)



    this.setState({
      ...this.state,
      answer: {
        ...this.state.answer,
        [id]: answer
      }
    })


    // console.log("STATE:", this.state)




  }

  setNextQuestion() {

    var counter = this.state.counter + 1;
    var nextQuestionId = this.state.questionId + 1;
    var lastQuestionId = this.state.questionId;
    var lastAnwser = this.state.answer[lastQuestionId];
    var islastQuestion = false;
    var Pi = 0;
    var ue = '';
    var ui = [];
    // console.log("Last question id:", lastQuestionId)
    // console.log("Last lastAnwser:", lastAnwser)



    if (lastQuestionId === 0) {
      this.setState({
        rooms: lastAnwser,

      })


    }
    if (lastQuestionId === 2) {
      if (parseInt(this.state.rooms) !== 1) { // rooms > 1
        if (lastAnwser === "A vista") {
          nextQuestionId = 6

        } else { // A incasso
          // nextQuestionId = 4






          Pi = this.getPi();
          ue = '';
          ui = [];
          if (Pi <= 6000) {
            ue = 'DUAL 7750943';
          } else if (6000 < Pi && Pi <= 7500) {
            ue = 'TRIAL 7750944';
          } else if (7500 < Pi && Pi <= 10000) {
            ue = 'POKER 7750945';
          } else {
            ue = 'PenTA 7715375';
          }

          this.state.answer[1].forEach(room => {

            var tempPi = this.getPiSingle(room)
            var roomUi = ''
            if (tempPi <= 2700) {
              roomUi = '7799665';
            } else if (2700 < tempPi && tempPi <= 3500) {
              roomUi = '7799666';

            } else {
              roomUi = '7799667';
            }

            ui.push(roomUi)
          });
          this.setState({
            result: {
              code: 'vitoclima300s',
              UE: ue,
              UI: ui,
              type: 'hidden',
              power: Pi

            }
          })








        }
      } else { // rooms = 1
        // console.log(this.state.rooms)

        if (lastAnwser === "A vista") {
          // console.log(this.state.rooms);

          nextQuestionId = 3;
          // console.log('nextQuestionId: ', nextQuestionId);

        } else { // a incasso

          Pi = this.getPi();
          ue = '';
          if (Pi > 3500) {
            ue = 'ZK05913';
          } else {
            ue = 'ZK05912';
          }
          this.setState({
            result: {
              code: 'vitoclima242s',
              UE: ue,
              UI: [ue],
              type: 'builtin',
              power: Pi
            }
          })
        }
      }

    }


    if (lastQuestionId === 3) {
      if (lastAnwser === "NORD") {
        nextQuestionId = 5

      } else {
        Pi = this.getPi();
        ue = '';
        if (Pi <= 2700) {
          ue = 'ZK04672';
        } else if (2700 < Pi && Pi <= 3500) {
          ue = 'ZK04673';
        } else if (3500 < Pi && Pi <= 5000) {
          ue = 'ZK04674';
        } else {
          ue = 'ZK04675';
        }
        this.setState({
          result: {
            code: 'vitoclima200s',
            UE: ue,
            UI: [ue],
            type: 'split',
            power: Pi

          }
        })
      }

    }

    // if (lastQuestionId === 4) {
    //     if(lastAnwser === 1){
    //       Pi = this.getPi();
    //       ue = '';
    //       ui = [];
    //       if (Pi <= 6000) {
    //         ue = 'DUAL 7750943';
    //       } else if( 6000 < Pi && Pi <= 7500) {
    //         ue = 'TRIAL 7750944';
    //       } else if( 7500 < Pi && Pi <= 10000) {
    //       ue = 'POKER 7750945';
    //     }else{
    //       ue = 'PenTA 7715375';
    //     }

    //     this.state.answer[1].forEach(room => {

    //       var tempPi = this.getPiSingle(room)
    //       var roomUi = ''
    //       if (tempPi <= 2700) {
    //         roomUi = '7799665';
    //       } else if( 2700 < tempPi && tempPi <= 3500) {
    //         roomUi = '7799666';

    //       }else{
    //         roomUi = '7799667';
    //       }

    //       ui.push(roomUi)
    //     });
    //       this.setState({
    //         result: {
    //           code: 'vitoclima300s',
    //           UE: ue,
    //           UI: ui,
    //           type: 'hidden',
    //           power: Pi

    //         }
    //       })

    //   }else{
    //     Pi = this.getPi();
    //     ue = '';
    //     ui = [];
    //     if (Pi <= 6000) {
    //       ue = 'DUAL 7750943';
    //     } else if( 6000 < Pi && Pi <= 7500) {
    //       ue = 'TRIAL 7750944';
    //     } else if( 7500 < Pi && Pi <= 10000) {
    //     ue = 'POKER 7750945';
    //   }else{
    //     ue = 'PenTA 7715375';
    //   }

    //   this.state.answer[1].forEach(room => {

    //     var tempPi = this.getPiSingle(room)
    //     var roomUi = ''
    //     if (tempPi <= 2700) {
    //       roomUi = '7799665';
    //     } else if( 2700 < tempPi && tempPi <= 3500) {
    //       roomUi = '7799666';

    //     }else{
    //       roomUi = '7799667';
    //     }

    //     ui.push(roomUi)
    //   });
    //     this.setState({
    //       result: {
    //         code: 'vitoclima300s',
    //         UE: ue,
    //         UI: ui,
    //         type: 'hidden',
    //         power: Pi

    //       }
    //     })
    //   }


    // }
    if (lastQuestionId === 5) {

      Pi = this.getPi();
      ue = '';
      if (Pi <= 2700) {
        ue = 'ZK03132';
      } else if (2700 < Pi && Pi <= 3500) {
        ue = 'ZK03132';

      } else {
        ue = 'ZK03134';
      }
      if (lastAnwser === "Bianco") {

        this.setState({
          result: {
            code: 'vitoclima232s',
            UE: ue,
            UI: [ue],
            type: 'split',
            power: Pi

          }
        })
      } else {
        this.setState({
          result: {
            code: 'vitoclima232s',
            UE: ue,
            UI: [ue],
            color: 'black',
            type: 'split',
            power: Pi

          }
        })
      }


    }

    if (lastQuestionId === 6) {

      Pi = this.getPi();
      ue = '';
      ui = [];
      if (Pi <= 6000) {
        ue = 'DUAL 7750943';
      } else if (6000 < Pi && Pi <= 7500) {
        ue = 'TRIAL 7750944';
      } else if (7500 < Pi && Pi <= 10000) {
        ue = 'POKER 7750945';
      } else {
        ue = 'PenTA 7715375';
      }

      if (lastAnwser === "A parete tipo \"split\" ") {


        // console.log(lastAnwser)
        this.state.answer[1].forEach(room => {

          var tempPi = this.getPiSingle(room)
          var roomUi = ''
          if (tempPi <= 2000) {
            roomUi = '7750940';
          } else if (2000 < tempPi && tempPi <= 2700) {
            roomUi = '7750941';

          } else if (2700 < tempPi && tempPi < 3500) {
            roomUi = '7532839';

          }
          else {
            roomUi = '7750942';
          }

          ui.push(roomUi)
        });
        this.setState({
          result: {
            code: 'vitoclima300s',
            UE: ue,
            UI: ui,
            type: 'split',
            power: Pi

          }
        })

      } else { //FANCOIL
        this.state.answer[1].forEach(room => {

          var tempPi = this.getPiSingle(room)
          var roomUi = ''
          if (tempPi <= 2700) {
            roomUi = '7799670';
          } else if (2700 < tempPi && tempPi <= 3500) {
            roomUi = '7799671';

          } else {
            roomUi = '7799672';
          }

          ui.push(roomUi)
        });
        this.setState({
          result: {
            code: 'vitoclima300s',
            UE: ue,
            UI: ui,
            type: 'fancoil',
            power: Pi


          }
        })
      }
      islastQuestion = true


    }
    if (!islastQuestion) {
      this.setState({
        lastQuestion: islastQuestion,
        counter: counter,
        questionId: nextQuestionId,
        id: quizQuestions[nextQuestionId].id,
        question: quizQuestions[nextQuestionId].question,
        answerOptions: quizQuestions[nextQuestionId].answers,
      })
    }



  }

  getResults() {
    const answersCount = this.state.answersCount;
    const answersCountKeys = Object.keys(answersCount);
    const answersCountValues = answersCountKeys.map(key => answersCount[key]);
    const maxAnswerCount = Math.max.apply(null, answersCountValues);

    return answersCountKeys.filter(key => answersCount[key] === maxAnswerCount);
  }
  getPiSingle = (n) => {
    var k = 35;
    var res = parseFloat(n) * 2.7 * k

    return res
  }

  getPi = () => {
    var pi1Kw = 0;
    var pi2Kw = 0;
    var pi3Kw = 0;
    var pi4Kw = 0;
    var pi5Kw = 0;
    var k = 35;
    if (typeof this.state.answer[1] !== 'undefined') {
      // console.log("Answers: ", this.state.answer[1][0])

      pi1Kw = parseFloat(this.state.answer[1][0]) * 2.7 * k
      pi2Kw = parseFloat(this.state.answer[1][1]) * 2.7 * k
      pi3Kw = parseFloat(this.state.answer[1][2]) * 2.7 * k
      pi4Kw = parseFloat(this.state.answer[1][3]) * 2.7 * k
      pi5Kw = parseFloat(this.state.answer[1][4]) * 2.7 * k

    }

    var piSumKw = pi1Kw + (pi2Kw ? pi2Kw : 0) + (pi3Kw ? pi3Kw : 0) + (pi4Kw ? pi4Kw : 0) + (pi5Kw ? pi5Kw : 0);
    // console.log("Pi :", piSumKw);

    return piSumKw
  }

  renderQuiz() {
    return (
      <Quiz
        lastQuestion={this.state.lastQuestion}
        id={this.state.id}
        answer={this.state.answer}
        answerOptions={this.state.answerOptions}
        questionId={this.state.questionId}
        question={this.state.question}
        questionTotal={quizQuestions.length}
        onAnswerSelected={this.handleAnswerSelected}
        handleReset={this.handleReset}
      />
    );
  }

  showForm() {


    this.setState({
      showForm: true
    })


    console.log(this)

  }
  renderResult() {
    return <Result answer={this.state.answer} result={this.state.result} showForm={this.showForm}
    />;
  }
  renderForm() {
    return <Form answer={this.state.answer} result={this.state.result} state={this.state} />;
  }

  render() {

    return (
      <MuiThemeProvider theme={muiTheme}>
        <div className="App">




          {(!this.state.showForm && !this.state.result.UI) && this.renderQuiz()}
          {(!this.state.showForm && this.state.result.UI) && this.renderResult()}
          {this.state.showForm && this.renderForm()}
          {/* {this.renderForm()} */}


        </div>
      </MuiThemeProvider>
    );
  }
}

export default App;

