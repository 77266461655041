
import React from 'react';
import PropTypes from 'prop-types';
import { animations } from 'react-animation'
import Stepper from '@material-ui/core/Stepper';
import Typography from '@material-ui/core/Typography';
import Step from '@material-ui/core/Step';
import StepButton from '@material-ui/core/StepButton';
import AnswerOption from '../components/AnswerOption';
import AnswerSelect from '../components/AnswerSelect';
import { lighten, makeStyles, withStyles} from '@material-ui/core/styles';

import Question from '../components/Question';
import LinearProgress from '@material-ui/core/LinearProgress';
import TextFieldContainer from '../components/TextFieldContainer';
import quizQuestions from '../api/quizQuestions';
import { Button } from '@material-ui/core';




const useStyles = makeStyles(theme => ({
    root: {
        paddingBottom: '100px',
        alignItems: 'center',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        width: '100%',
        // background: 'url("https://cdn0.scrvt.com/2828ebc457efab95be01dd36047e3b52/4404c3fff65bfd7b/7e8af90241b3/v/2b9ee4c56a2c/cta_bg2.png") center center / cover no-repeat scroll padding-box border-box transparent',
    },
    container: {
        paddingBottom: '40px',
        borderRadius: '10px',
        paddingTop: '20px',
        [theme.breakpoints.down('sm')]: {
            width: '98%',
        },
        [theme.breakpoints.up('md')]: {
            width: '70%',
        },
        [theme.breakpoints.up('lg')]: {
            width: '70%',
        },
        background: 'white',
        boxShadow: '14px 17px 50px 0px rgba(0,0,0,0.55)',

    },
    containerFull: {
        paddingBottom: '40px',
        borderRadius: '10px',
        paddingTop: '20px',
        width: '100%',
        background: 'white',
       

    },
    backButton:{
        backgroundColor: '#ff3e17',
        fontSize: '18px',
        textTransform: 'unset',
        boxShadow: 'unset',
        border: '1px solid',
        marginRight: theme.spacing(1),


        '&:hover': {
            backgroundColor: 'unset',
            color: '#ff3e17',
            border: '1px solid'
         },
    },
    MuiStepIconActive:{
        background: 'black'
    },
    section1: {
        color: '#767b91',
        paddingBottom: '40px',
        paddingTop: '40px',
        textAlign: 'center'
    },

    completed: {
        display: 'inline-block',
    },
    instructions: {
        marginTop: theme.spacing(1),
        marginBottom: theme.spacing(1),
    },
    disclaimer: {
        paddingLeft: '20px',
        paddingRight: '20px'

    }
}));
function getSteps() {
    return ['1/3', '2/3', '3/3'];
}


function Quiz(props) {
    const classes = useStyles();
    const [activeStep, setActiveStep] = React.useState(0);
    // eslint-disable-next-line
    const [completed, setCompleted] = React.useState(new Set());
    // eslint-disable-next-line
    const [skipped, setSkipped] = React.useState(new Set());
    const steps = getSteps();
    const questionsCount = quizQuestions.length;
    function totalSteps() {
        return getSteps().length;
    }

    function isStepOptional(step) {
        return step === 14;
    }



    function skippedSteps() {
        return skipped.size;
    }

    function completedSteps() {
        return completed.size;
    }

    function allStepsCompleted() {
        return completedSteps() === totalSteps() - skippedSteps();
    }

    function isLastStep() {
        return activeStep === totalSteps() - 1;
    }
    // eslint-disable-next-line
    function handleNext() {
        const newActiveStep =
            isLastStep() && !allStepsCompleted()
                ? // It's the last step, but not all steps have been completed
                // find the first step that has been completed
                steps.findIndex((step, i) => !completed.has(i))
                : activeStep + 1;

        setActiveStep(newActiveStep);
    }

    // function handleComplete() {
    //     const newCompleted = new Set(completed);
    //     newCompleted.add(activeStep);
    //     setCompleted(newCompleted);

    //     /**
    //      * Sigh... it would be much nicer to replace the following if conditional with
    //      * `if (!this.allStepsComplete())` however state is not set when we do this,
    //      * thus we have to resort to not being very DRY.
    //      */
    //     if (completed.size !== totalSteps() - skippedSteps()) {
    //         handleNext();
    //     }
    // }
    function handleReset() {
        // console.log("handleReset")
        setActiveStep(0);
        setCompleted(new Set());
        setSkipped(new Set());
        props.handleReset();
    }

    function isStepSkipped(step) {
        return skipped.has(step);
    }

    function isStepComplete(step) {
        return completed.has(step);
    }

    function renderAnswerSelects(answers) {

        // answers.map((arraySelect) => {

        return (
            <AnswerSelect answerOptions={answers.map((regioni) => { return regioni })} onAnswerSelected={props.onAnswerSelected} questionId={props.questionId} />

        );
        // })

    }


    function renderAnswerOptions(key) {

        var maxAnswerOption = props.answerOptions.length;

        var percentageWidth = 100 / maxAnswerOption;


        return (
            <AnswerOption key={key.content} answerContent={key.content} answerType={key.type}
                answer={key.content}
                questionId={props.questionId} image={key.url} onAnswerSelected={props.onAnswerSelected} width={percentageWidth + "%"} />

        );




    }
    // const section1 = <section className={classes.section1}>
    //     <h2>Rispondi alle prossime domande per trovare il tuo condizionatore ideale</h2>
    // </section>;

    function renderQuestion(props) {
        switch (props.questionId) {
            case 3:
                return renderAnswerSelects(props.answerOptions)
            case 1:
                    return <TextFieldContainer RoomsCount={parseInt(props.answer[0])} questionId={props.questionId} onAnswerSelected={props.onAnswerSelected} />
            default:
                return props.answerOptions.map(renderAnswerOptions)
        }
    }


    return (

        <div style={style} className={classes.root}>
          
            <div className={classes.containerFull} >
          
                <Stepper alternativeLabel nonLinear activeStep={activeStep}  >
                    {steps.map((label, index) => {
                        const stepProps = {};
                        const buttonProps = {};
                        if (isStepOptional(index)) {
                            buttonProps.optional = <Typography variant="caption">Optional</Typography>;
                        }
                        if (isStepSkipped(index)) {
                            stepProps.completed = false;
                        }
                        return (
                            <Step key={label} {...stepProps}
                           
                            >

                                <StepButton
                                    onClick={() => {
                                       props.handleReset()
                                        
                                    }
                                       }
                                    completed={isStepComplete(index)}
                                    {...buttonProps}
                                >
                                    {label}
                                </StepButton>
                            </Step>
                        );
                    })}

                </Stepper>
              
                <Question content={props.question} />
                <BorderLinearProgress
                    className={classes.margin}
                    variant="determinate"
                    color="secondary"
                    value={props.questionId / questionsCount * 100}
                />
                <div key={props.questionId}>

                {renderQuestion(props)}
                </div>
                {quizQuestions[props.questionId ].disclaimer && 
                <div className={classes.disclaimer}>
                    <p >*{quizQuestions[1].disclaimer}</p>
                    <Button className={classes.backButton} variant="contained" color="primary" onClick={() => handleReset()} >Indietro</Button>
                </div>
                }
               
            </div>
        </div>

    );
}


const BorderLinearProgress = withStyles({
    root: {
        height: 10,
        backgroundColor: lighten('#ff6c5c', 0.5),
        marginBottom: 40
    },
    bar: {
        borderRadius: 20,
        backgroundColor: '#ff6c5c',
    },
})(LinearProgress);

const style = {
    animation: animations.fadeInUp
}


Quiz.propTypes = {
    answer: PropTypes.object.isRequired,
    answerOptions: PropTypes.array.isRequired,
    question: PropTypes.string.isRequired,
    questionTotal: PropTypes.number.isRequired,
    onAnswerSelected: PropTypes.func.isRequired,
    lastQuestion: PropTypes.bool.isRequired
};

export default Quiz;
