import React from 'react';
import { makeStyles, withStyles } from '@material-ui/core/styles';
import { animations } from 'react-animation'

import Box from '@material-ui/core/Box';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import Results from '../api/quizResults.js';

import InputLabel from '@material-ui/core/InputLabel';
import FormControl from '@material-ui/core/FormControl';
import Select from '@material-ui/core/Select';

// import InputBase from '@material-ui/core/InputBase';
import TextField from '@material-ui/core/TextField';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { provinceData, fasceOrarie, periodo } from '../api/formData';
import { KeyboardDatePicker } from '@material-ui/pickers';

function Form(props) {

    const [selectedPeriodo, setSelectedPeriodo] = React.useState("");

    const [address, setAddress] = React.useState("");

    const [zip, setZip] = React.useState("");
    const [city, setCity] = React.useState("");
    const [responseSubmit, setResponseSubmit] = React.useState(false);
    const [province, setProvince] = React.useState("");
    const [name, setName] = React.useState(false);
    const [lastname, setLastName] = React.useState(false);
    const [email, setEmail] = React.useState("");
    const [phone, setPhone] = React.useState("");

    var tomorrow = new Date();
    tomorrow.setDate(new Date().getDate() + 1);
    const [selectedDate, setSelectedDate] = React.useState(tomorrow);
    const [fasciaOraria, setFasciaOraria] = React.useState("");
    const [privacy, setPrivacy] = React.useState(false);
    const [marketing, setMarketing] = React.useState(false);
    const [consent, setConsent] = React.useState(false);



    const style = {
        animation: animations.fadeInUp
    };
    const useStyles = makeStyles(theme => ({
        root: {
         
            paddingTop: '20px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '94%',
            margin: 'auto'
        },
        response: {
           
            paddingTop: '20px',
            alignItems: 'center',
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'row',
            width: '100%',
            margin: 'auto'
        },
        connectorActive: {
            '& $connectorLine': {
                borderColor: '#ff3e17',
            },
        },
        container: {
            // paddingBottom: '40px',
            paddingTop: '40px',
            width: '90%',
            background: 'white',
            borderRadius: '10px',
            boxShadow: '14px 17px 50px 0px rgba(0,0,0,0.55)'

        },
        containerFull: {

            borderRadius: '10px',
            paddingTop: '20px',
            width: '100%',
            background: 'white',
           
    
        },
        form: {
            // paddingBottom: '40px',
            paddingTop: '20px',
            width: '80%',
            background: 'white',
            margin: '10px'
        },
        button: {
            backgroundColor: '#ff3e17',
            fontSize: '18px',
            textTransform: 'unset',
            boxShadow: 'unset',
            color: '#fff',
            border: '1px solid',
            marginRight: theme.spacing(1),

            '&:hover': {
                backgroundColor: 'unset',
                color: '#ff3e17',
                border: '1px solid',
                boxShadow: 'unset',

            },
        },
        formLabel: {
            width: '140px',
        },
        section: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            justifyContent: 'space-around',
            flexDirection: 'row'
        },
        sectionApcentance: {
            display: 'flex',
            flexWrap: 'wrap',
            width: '100%',
            flexDirection: 'column'
        },
        radioGroup: {
            justifyContent: 'center'
        },
        inputField: {
            // margin: '10px',  
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                width: '50%',
            },
            [theme.breakpoints.up('lg')]: {
                width: '50%',
            }

        },
        input: {
            width: '100%',
            paddingRight: '5px',
            paddingleft: '5px',
            paddingBottom: '10px'
        }
        ,
        selectProvince: {
            width: '100%',
            paddingRight: '5px',
            paddingleft: '5px',
            paddingBottom: '10px'
        },
        addressText: {

            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                width: '80%',
            },
            [theme.breakpoints.up('lg')]: {
                width: '80%',
            }
        },
        zipText: {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            [theme.breakpoints.up('sm')]: {
                width: '100%',
            },
            [theme.breakpoints.up('md')]: {
                width: '20%',
            },
            [theme.breakpoints.up('lg')]: {
                width: '20%',
            }
        },

    }));

    const RedCheckbox = withStyles({
        root: {
            color: '#F83D17',
            '&$checked': {
                color: '#E73413',
            },
        },
        checked: {},
    })((props) => <Checkbox color="default" {...props} />);

    const classes = useStyles();

    const handleChangePeriodo = (event) => {
        setSelectedPeriodo(event.target.value);
    }
    const handleChangeZip = (event) => {
        setZip(event.target.value);

    }
    const handleChangeCity = (event) => {
        setCity(event.target.value);
    }
    const handleChangeAddress = (event) => {
        setAddress(event.target.value);
    }

    const handleChangeName = (event) => {
        setName(event.target.value);
    }
    const handleChangeLastName = (event) => {
        setLastName(event.target.value);
    }
    const handleChangeEmail = (event) => {
        setEmail(event.target.value);
    }
    const handleChangePhone = (event) => {
        setPhone(event.target.value);
    }


    const handleChangeProvince = (event) => {
        setProvince(event.target.value);
    }
    const handleChangeFasciaOraria = (event) => {
        setFasciaOraria(event.target.value);
    }
    const handleDateChange = (dateEntry) => {
        setSelectedDate(dateEntry);
        // console.log(dateEntry)
    };

    const handlePrivacyChange = (event) => {

        setPrivacy(event.target.checked);
    };

    const handleMarketingChange = (event) => {
        setMarketing(event.target.checked);
    };
    const handleConsentChange = (event) => {
        setConsent(event.target.checked);
    };


    const handleSubmit = (event) => {

        var xhr = new XMLHttpRequest();
        var url = 'https://api.hsforms.com/submissions/v3/integration/submit/4948521/54d58153-5ca7-43f4-8570-2d07e1d41105'

        var dataSelezionata = selectedDate.getFullYear() + "-" + ("0" + (selectedDate.getMonth() + 1)).slice(-2) + "-" + ("0" + (selectedDate.getDate())).slice(-2);
        
        // alert(`
        //   Periodo: ${selectedPeriodo}
        //   Indirizzo: ${address}
        //   Zip: ${zip}
        //   Citta: ${city}
        //   Provincia: ${province}
        //   Nome: ${name}
        //   Cognome: ${lastname}
        //   Email: ${email}
        //   Telefono: ${phone}
        //   Data: ${dataSelezionata}
        //   Fascia Oraria: ${fasciaOraria}
        //   Privacy: ${privacy}
        //   Consenso Terzi: ${consent}
        //   Marketing: ${marketing}
        // `);

        let numero_stanze_da_climatizzare = "";
        let mq_da_climatizzare = "";
        let tipologia_unita_interna = "";
        let regione = "";
        let tipologia_unita_interna_a_vista = "";
        let vitoclima_unita_interna = "";
        let configuratore_vitoclima_soluzione = "";
        let potenza_vitoclima = "";
        if (props.state.answer[0]) {
            if (parseInt(props.state.answer[0]) > 2) {
                numero_stanze_da_climatizzare = '3+';

            } else {
                numero_stanze_da_climatizzare = props.state.answer[0];

            }
        }

        if (props.state.answer[1]) {
            var temp_mq = 0
            props.state.answer[1].forEach(element => {
                temp_mq += parseInt(element)
            });
            if (temp_mq > 50) {
                mq_da_climatizzare = ">50 mq"
            } else if (temp_mq <= 50 && temp_mq >= 30) {
                mq_da_climatizzare = "30-50 mq"
            } else {
                mq_da_climatizzare = "< 30 mq"
            }

        }

        if (props.state.answer[2]) {
            tipologia_unita_interna = props.state.answer[2];
        }

        if (props.state.answer[3]) {
            regione = props.state.answer[3];
        }

        if (props.state.answer[6]) {
            tipologia_unita_interna_a_vista = props.state.answer[6];
        }


        if (props.result.code) {

            configuratore_vitoclima_soluzione = Results[props.result.code].title

        }
        if (props.state.answer[5]) {
            vitoclima_unita_interna = props.state.answer[5] === "Bianco" ? " White" : " Black";
            configuratore_vitoclima_soluzione += vitoclima_unita_interna === "Bianco" ? " White" : " Black"
        }
        if (props.result.power) {
            potenza_vitoclima = props.result.power
        }

        var data = {
            "submittedAt": Date.now(),
            "fields": [
                {
                    "name": "firstname",
                    "value": name
                },
                {
                    "name": "lastname",
                    "value": lastname
                },
                {
                    "name": "email",
                    "value": email
                },
                {
                    "name": "phone",
                    "value": phone
                },
                {
                    "name": "address",
                    "value": address
                },
                {
                    "name": "city",
                    "value": city
                },
                {
                    "name": "zip",
                    "value": zip
                },
                {
                    "name": "provincia",
                    "value": province
                },
                {
                    "name": "numero_stanze_da_climatizzare",
                    "value": numero_stanze_da_climatizzare
                },
                {
                    "name": "potenza_vitoclima",
                    "value": potenza_vitoclima
                },
                {
                    "name": "mq_da_climatizzare",
                    "value": mq_da_climatizzare
                },
                {
                    "name": "tipologia_unita_interna",
                    "value": tipologia_unita_interna
                },
                {
                    "name": "regione",
                    "value": regione
                },
                {
                    "name": "tipologia_unita_interna_a_vista",
                    "value": tipologia_unita_interna_a_vista
                },
                {
                    "name": "vitoclima_unita_interna",
                    "value": vitoclima_unita_interna
                },
                {
                    "name": "configuratore_vitoclima_soluzione",
                    "value": configuratore_vitoclima_soluzione
                },
                {
                    "name": "periodo_intervento",
                    "value": selectedPeriodo
                },
                {
                    "name": "giorno_telefonate",
                    "value": dataSelezionata
                },
                {
                    "name": "orario_telefonate_jotform",
                    "value": fasciaOraria
                },
                {
                    "name": "privacy_policy",
                    "value": privacy
                },
                {
                    "name": "consenso_terzi",
                    "value": consent
                },
                {
                    "name": "consenso_marketing_residenziale",
                    "value": marketing
                },
            ],
            // "legalConsentOptions":{ // Include this object when GDPR options are enabled
            //   "consent":{
            //     "consentToProcess":true,
            //     "text":"I agree to allow Example Company to store and process my personal data.",
            //     "communications":[
            //       {
            //         "value":true,
            //         "subscriptionTypeId":999,
            //         "text":"I agree to receive marketing communications from Example Company."
            //       }
            //     ]
            //   }
            // }
        }

        var final_data = JSON.stringify(data)
        xhr.open('POST', url);
        // Sets the value of the 'Content-Type' HTTP request headers to 'application/json'
        xhr.setRequestHeader('Content-Type', 'application/json');

        xhr.onreadystatechange = function () {
            if (xhr.readyState === 4 && xhr.status === 200) {
                //   alert(xhr.responseText); 
                setResponseSubmit(true);
            } else if (xhr.readyState === 4 && xhr.status === 400) {
                alert(xhr.responseText); // Returns a 400 error the submission is rejected.


            } else if (xhr.readyState === 4 && xhr.status === 403) {
                alert(xhr.responseText); // Returns a 403 error if the portal isn't allowed to post submissions.           
            } else if (xhr.readyState === 4 && xhr.status === 404) {
                alert(xhr.responseText); //Returns a 404 error if the formGuid isn't found     
            }
        }


        // Sends the request 

        xhr.send(final_data)

        event.preventDefault();
    }
    function disableWeekends(date) {
        return date.getDay() === 0 || date.getDay() === 6;
    }

    return (


        <div style={style} className={classes.root}>

            <div className={classes.containerFull} >


                <Box display="flex" className={classes.outer} justifyContent="center" flexWrap="wrap">
                    {!responseSubmit &&
                        <form id="form" className={classes.root} autoComplete="off" onSubmit={handleSubmit}>

                            <h2>1. Quando vorresti effettuare l'intervento?</h2>
                            <div className={classes.section}>

                                <FormControl component="fieldset">
                                    <RadioGroup className={classes.radioGroup} row aria-label="position" name="position" defaultValue="top" >
                                        {periodo.map((option) => (
                                            <FormControlLabel
                                                key={option.value}
                                                value={option.value}
                                                control={<Radio onChange={handleChangePeriodo} color="primary" required
                                                />}
                                                label={option.label}
                                                className={classes.formLabel}
                                            />
                                        ))}
                                    </RadioGroup>
                                </FormControl>
                            </div>
                            <h2>2. Dove verrà effettuato l'intervento?</h2>
                            <div className={classes.section}>
                                <div className={classes.addressText}>
                                    <TextField
                                        fullWidth
                                        required
                                        id="address"
                                        onChange={handleChangeAddress}
                                        label="Indirizzo"
                                        variant="outlined"
                                        className={classes.input}
                                    />
                                </div>
                                <div className={classes.zipText}
                                >

                                    <TextField
                                        fullWidth
                                        required
                                        id="zip"
                                        onChange={handleChangeZip}
                                        label="Cap"
                                        variant="outlined"
                                        className={classes.input}
                                    />
                                </div>


                            </div>
                            <div className={classes.section}>
                                <div className={classes.inputField}>
                                    <TextField
                                        fullWidth
                                        variant="outlined"
                                        required
                                        id="city"
                                        onChange={handleChangeCity}
                                        label="Città"
                                        className={classes.input}

                                    />
                                </div>
                                <div className={classes.inputField}>

                                    <FormControl variant="outlined" className={classes.selectProvince}>
                                        <InputLabel htmlFor="province">Provincia*</InputLabel>
                                        <Select
                                            autoWidth={false}
                                            fullWidth
                                            native
                                            required
                                            value={province}
                                            onChange={handleChangeProvince}
                                            label="Provincia"
                                            inputProps={{
                                                name: 'province',
                                                id: 'province',
                                            }}
                                        >
                                            <option aria-label="None" value="" />

                                            {provinceData.map((city) => (
                                                <option key={city.sigla} value={city.sigla + " - " + city.nome}>{city.sigla} - {city.nome}</option>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>


                            <h2>3. Abbiamo bisogno delle ultime informazioni</h2>
                            <div className={classes.section}>
                                <div className={classes.inputField}>

                                    <TextField
                                        required
                                        id="firstname"
                                        label="Nome"
                                        variant="outlined"
                                        onChange={handleChangeName}
                                        className={classes.input}
                                    />
                                </div>
                                <div className={classes.inputField}>

                                    <TextField
                                        required
                                        id="lastname"
                                        label="Cognome"
                                        variant="outlined"
                                        className={classes.input}
                                        onChange={handleChangeLastName}


                                    />
                                </div>
                            </div>
                            <div className={classes.section}>
                                <div className={classes.inputField}>

                                    <TextField

                                        required
                                        id="email"
                                        type="email"
                                        label="Email"
                                        onChange={handleChangeEmail}
                                        variant="outlined"
                                        className={classes.input}

                                    />
                                </div>
                                <div className={classes.inputField}>

                                    <TextField
                                        required
                                        id="phone"
                                       
                                        InputProps={{
                                            inputProps: {
                                                maxLength: 20,
                                                minLength: 7
                                            }
                                        }}
                                        label="Telefono"
                                        variant="outlined"
                                        onChange={handleChangePhone}
                                        className={classes.input}


                                    />
                                </div>
                            </div>
                            <h2>4. Ricevi la nostra chiamata quando hai maggiore disponibilità! Prenota una data</h2>

                            <div className={classes.section}>
                                <div className={classes.inputField}>
                                    <KeyboardDatePicker
                                        disableToolbar
                                        inputVariant="outlined"
                                        minDate={selectedDate}
                                        format="dd/MM/yyyy"
                                        required
                                        id="dateSelect"
                                        label="Data"
                                        className={classes.input}
                                        value={selectedDate}
                                        onChange={handleDateChange}
                                        KeyboardButtonProps={{
                                            'aria-label': 'cambia data',

                                        }}
                                        shouldDisableDate={disableWeekends}
                                    />
                                </div>


                                <div className={classes.inputField}>

                                    <FormControl variant="outlined" className={classes.input}>
                                        <InputLabel htmlFor="fasciaOraria">Fascia Oraria*</InputLabel>
                                        <Select
                                            required
                                            autoWidth={false}
                                            fullWidth
                                            native
                                            value={fasciaOraria}
                                            onChange={handleChangeFasciaOraria}
                                            label="Fascia Oraria"
                                            inputProps={{
                                                name: 'fasciaOraria',
                                                id: 'fasciaOraria',
                                            }}
                                        >
                                            <option aria-label="None" value="" />

                                            {fasceOrarie.map((fascia) => (
                                                <option key={fascia.key} value={fascia.value}>{fascia.value}</option>
                                            ))}

                                        </Select>
                                    </FormControl>
                                </div>
                            </div>
                            <br />
                            <div className={classes.sectionApcentance}>
                            <span>Avendo preso visione dell'informativa privacy <a rel="noopener noreferrer"
                                                    href="https://www.viessmann.it/it/informativa-privacy.html"
                                                    target="_blank"
                                                >
                                                    (leggi qui)
                                            </a> autorizzo il trattamento dei miei dati personali per:</span>

                                <FormControlLabel
                                    control={<RedCheckbox required checked={consent} onChange={handleConsentChange} name="consent" />}
                                    label=" (*) Contatto di Viessmann s.r.l.u con modalità automatiche (e-mail, sms e app) e tradizionali (posta e telefonate) o trasmissione degli stessi a soggetti terzi specializzati (ad esempio installatori Partner, Centri di Assistenza Tecnica e Progettisti), che a loro volta garantiscano la tutela dei miei diritti."
                                />
                                
                                <FormControlLabel
                                    control={<RedCheckbox checked={marketing} onChange={handleMarketingChange} name="marketing" />}
                                    label=" Invio di comunicazioni commerciali, pubblicitarie e ricerche di mercato su prodotti/servizi di Viessmann s.r.l.u. con modalità automatiche (e-mail, sms e app) e tradizionali (posta e telefonate)."
                                />

                            </div>
                            <Button className={classes.button} type="submit" variant="contained" color="primary" >Contattaci</Button>

                        </form>
                    }
                    {responseSubmit &&
                        <div className={classes.response}>
                            <h2>Grazie per la tua richiesta!</h2>
                            <h3>Verrai contattato al più presto da un nostro operatore.</h3>
                        </div>
                    }
                </Box>






            </div>
        </div>
    );
}

// Form.propTypes = {
//     results: PropTypes.object.isRequired,
// };

export default Form;
