import split from '../images/split.png'
import builtin from '../images/builtin.jpg'
import fancoil from '../images/fancoil.jpg'


var quizQuestions = [

    {   id: "0",
        question: "Quante stanze vuoi climatizzare?",
        answers: [
            {
                type: "Option",
                content: "1"
            },
            {
                type: "Option",

                content: "2"
            },
            {
                type: "Option",

                content: "3"
            },
            {
                type: "Option",

                content: "4"
            },
            {
                type: "Option",

                content: "5"
            }
        ]
    },
    {   id: "1",
        question: "Inserisci la metratura per ogni stanza",
        answers: [
        ],
        disclaimer:"In relazione alle potenze disponibili, per metrature superiori ai 35 mq è necessario suddividere il calcolo su più unità interne.",
    },
    { id: "2",
    question: "Quale tipologia di unità interna preferisci?",
    answers: [
        {
            type: "Option",
            content: "A vista",
            url: split,

        },
        {
            type: "Option",
            content: "A incasso",
             url: builtin

        }
    ]
    },
    {   id: "3",
        question: "In quale zona climatica si trova l'edificio?",
        answers: [
            {
                type: "Select",
                regioni: [
                    {
                        regione: "Abruzzo",
                        value: "SUD"
                    },
                    {
                        regione: "Basilicata",
                        value: "SUD"
                    },
                    {
                        regione: "Calabria",
                        value: "SUD"
                    },
                    {
                        regione: "Campania",
                        value: "SUD"
                    },
                    {
                        regione: "Emilia Romagna",
                        value: "NORD"
                    },
                    {
                        regione: "Friuli Venezia Giulia",
                        value: "NORD"
                    },
                    {
                        regione: "Lazio",
                        value: "NORD"
                    },
                    {
                        regione: "Liguria",
                        value: "NORD"
                    },
                    {
                        regione: "Lombardia",
                        value: "NORD"
                    },
                    {
                        regione: "Marche",
                        value: "SUD"
                    },
                    {
                        regione: "Molise",
                        value: "SUD"
                    },
                    {
                        regione: "Piemonte",
                        value: "NORD"
                    },
                    {
                        regione: "Puglia",
                        value: "SUD"
                    },
                    {
                        regione: "Sardegna",
                        value: "SUD"
                    },
                    {
                        regione: "Sicilia",
                        value: "SUD"
                    },
                    {
                        regione: "Toscana",
                        value: "SUD"
                    },
                    {
                        regione: "Trentino Alto Adige",
                        value: "NORD"
                    },
                    {
                        regione: "Umbria",
                        value: "SUD"
                    },
                    {
                        regione: "Valle d'Aosta",
                        value: "NORD"
                    },
                    {
                        regione: "Veneto",
                        value: "NORD"
                    },


                ]
            }
        ]
    },
    { id: "4",
        question: "Quante zone controsoffitabili dispone la tua casa?",
        answers: [
            {
                type: "Option",
                content: "1"
            },
            {
                type: "Option",
                content: "2"
            }
        ]
    },
    {   id: "5",
        question: "Quale unità interna a vista preferisci?",
        answers: [
            {
                type: "Option",
                content: "Bianco",
                // url: 'https://www.negozimobilidesign.it/public/articoli/articoli-100118010951-4bd0d35e5230ef36d69000a7f546b59f.jpg',
            },
            {
                type: "Option",
                content: "Nero",
            //     url: 'https://www.boscolomastercompany.com/media/1380/foto-7-cucina-anni-80.jpg',
            }
        ]
    },

    { id: "6",
        question: "Quale dipologia di unità interna a vista?",
        answers: [
            {
                type: "Option",
                content: "A parete tipo \"split\" ",
                url: split,

            },
            {
                type: "Option",
                content: "A mobiletto tipo \"fancoil\" ",
                url: fancoil,

            }
        ]
    },
    
];

export default quizQuestions;
